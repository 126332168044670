@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./animations.scss";
@import "./fonts.scss";
@import "./line-awesome/1.3.0/scss/line-awesome.scss";

html,
body {
    height: 100%;
    font-family: 'Jost';
    width: 100%;
}


html,
body {
    height: 100%;
    font-family: 'Jost';
    width: 100%;
}

.bg-overlay {
    background:rgb(51 51 51 / 26%);
}

[v-cloak] > * { display:none }

.loader {
    border-top-color: #3498db !important;
    &.slow {
        -webkit-animation: spinner 1.5s linear infinite;
        animation: spinner 1.5s linear infinite;
    }
    &.fast {
        -webkit-animation: spinner 0.7s linear infinite;
        animation: spinner 0.7s linear infinite;
    }
}

@-webkit-keyframes spinner {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/**
 * Forms
**/
.form-input {
    label {
        @apply text-gray-800 py-2 font-semibold;
    }
    input {
        @apply border-2 border-blue-200 bg-blue-100 rounded p-2 w-full;
        &[disabled] {
            @apply bg-gray-300;
        }
    }
    p {
        @apply text-xs text-gray-600 py-1;
    }
}

.form-input-invalid {
    label {
        @apply text-red-500 py-2 font-semibold;
    }
    input {
        @apply border-2 border-red-400 bg-red-100 rounded p-2 w-full;
    }
    p {
        @apply text-xs text-red-500 py-1;
    }
}
.btn {
    @apply text-center rounded shadow border py-2 px-3;
}
.btn-blue {
    @apply border-blue-600 bg-blue-600 text-white;
}
.btn-red {
    @apply border-red-600 bg-red-600 text-white;
}

.pos-button-clicked {
    box-shadow: inset 0px 0px 5px 0px #a4a5a7;
}

.ns-table {
    @apply w-full;
    thead th {
        @apply border border-gray-300 bg-gray-200 p-2 text-gray-700;
    }
    tbody td {
        @apply border border-gray-200 p-2 text-gray-700;
    }
    tfoot td {
        @apply border border-gray-200 p-2 text-gray-700;
    }

    tbody > tr.info {
        @apply bg-blue-200;
    }

    tbody > tr.danger {
        @apply bg-red-200;
    }

    tbody > tr.success {
        @apply bg-green-200;
    }

    tbody > tr.green {
        @apply bg-yellow-200;
    }
}

#editor {
    h1 {
        @apply text-5xl font-bold;
    }
    
    h2 {
        @apply text-4xl font-bold;
    }
    
    h3 {
        @apply text-3xl font-bold;
    }
    
    h4 {
        @apply text-2xl font-bold;
    }
    
    h5 {
        @apply text-xl font-bold;
    }
}

#grid-items {
    .vue-recycle-scroller__item-wrapper {
        @apply grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-0 overflow-y-auto;
    }
    .vue-recycle-scroller__item-view {
        @apply cursor-pointer border h-32 lg:h-40 border-gray-200 flex flex-col items-center justify-center overflow-hidden;
        &:hover {
            @apply bg-gray-200;
        }
    }
}

/**
 * Components
**/

// Popup
.popup-heading {
    @apply p-2 flex justify-between items-center;
    h3 {
        @apply font-semibold;
    }
}